import React from "react";
import * as styles from "../index.module.scss";
import { alpha, Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { filter, get } from "lodash";
import Image from "../../image";
import { mediaTypes } from "../../../utils/media";
import NoImageRenderer from "../../no-image-renderer";
import TastePreferenceDecorator from "../TastePreferenceDecorator";
import QuantityInput from "../../quanitity-input";
import ComponentOptionPrice from "../ComponentOptionPrice";

const ModifierItem = ({
  index,
  componentOption,
  onClick,
  selected,
  disabled,
  variant,
  isMobile,
  appStyles,
  allergens,
  T,
  limit,
  orderItemComponent,
  shouldShowPlusSign,
  currencySymbol,
  posInnerMenuId,
}) => {
  const imageProps = {
    ...{
      wrapperStyle: {
        height: isMobile ? 82 : 120,
        width: isMobile ? 164 : 244,
      },
    },
  };

  const selectedStyles = {
    border: `1px solid ${appStyles.actionColor}`,
    "& .MuiCardContent-root": {
      backgroundColor: alpha(appStyles.actionColor, 1),
      borderRadius: 2,
    },
    [`& .${styles.ItemComponentOption} .MuiTypography-root`]: {
      color: `${appStyles.actionColor}`,
    },
    "& .MuiTypography-root": {
      fontWeight: "bold",
    },
  };

   const getMax = (limit) => {
      const lengthOfThisComponentOption = filter(
        orderItemComponent.chosenOrderItemComponentOptions,
        (val) => val === componentOption.id
      ).length;
      return lengthOfThisComponentOption < limit ? limit -  
        (orderItemComponent.chosenOrderItemComponentOptions.length - 
          lengthOfThisComponentOption):
            limit > 0 ? limit - (
              orderItemComponent.chosenOrderItemComponentOptions ?
              orderItemComponent.chosenOrderItemComponentOptions.length : 0) : 100
    };

  return (
    <Grid
      item
      key={index}
      display="flex"
      justifyContent="center"
      sx={{ ...(isMobile && { width: "50%" }) }}
    >
      <Card
        sx={{
          width: "100%",
          maxWidth: isMobile ? 164 : 244,
          border: `1px solid ${appStyles.textColor}`,
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? "not-allowed" : "pointer",
          borderRadius: 2,
          ...(selected && selectedStyles),
        }}
        onClick={
          disabled || variant === "multiple"
            ? () => {
                if (selected) {
                  onClick(0);
                } else {
                  onClick(1);
                }
              }
            : onClick
        }
      >
        <CardContent
          sx={{
            p: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            key={index}
            style={{
              // height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "82px",
            }}
          >
            {get(componentOption, "imageKey") ? (
              <Image
                mediaKey={componentOption.imageKey}
                mediaType={mediaTypes.menuItemModifier}
                imagePreview={componentOption.imagePreview}
                {...imageProps}
                imgStyle={{ objectFit: "contain" }}
              />
            ) : (
              <NoImageRenderer appStyles={appStyles} {...imageProps} />
            )}
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: `calc(100% - ${
                imageProps.wrapperStyle.height
              }px - 20px)`, // the height of the image + padding
              justifyContent: "space-between",
              padding: "10px",
              ...(isMobile && {
                padding: "12px 8px",
                minHeight: `calc(100% - ${
                  imageProps.wrapperStyle.height
                }px - 10px)`,
                alignItems: "stretch",
              }),
              boxSizing: "border-box",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // ...(isMobile && { padding: "7px 3px 5px 3px" }),
              }}
            >
              <TastePreferenceDecorator
                title={T(componentOption.title)}
                appStyles={appStyles}
                allergens={allergens}
                titleStyles={{
                  fontWeight: "bold",
                  color: appStyles.textColor,
                  fontSize: isMobile ? "14px" : "18px",
                }}
              />
              {componentOption.description && (
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    color: "text.secondary",
                    fontSize: isMobile ? "12px" : "16px",
                  }}
                >
                  {T(componentOption.description)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ComponentOptionPrice
                componentOption={componentOption}
                addPlusSign={shouldShowPlusSign && !appStyles.hidePlusSign}
                appStyles={appStyles}
                currencySymbol={currencySymbol}
                posInnerMenuId={posInnerMenuId}
                orderItemComponent={orderItemComponent}
              />
              {selected && variant === "multiple" && (
                <Box
                  sx={{
                    paddingLeft: "2px",
                    paddingTop: "8px",
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <QuantityInput
                    appStyles={appStyles}
                    readOnly
                    value={
                      filter(
                        orderItemComponent.chosenOrderItemComponentOptions,
                        (val) => val === componentOption.id
                      ).length
                    }
                    min={0}
                    max={getMax(limit)}
                    onChange={onClick}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ModifierItem;
